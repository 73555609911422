"use client";

import * as Sentry from "@sentry/nextjs";
import {useEffect} from "react";
import {CustomError} from "@/common/errors";

export default function ErrorPage({error}: Readonly<{ error: CustomError }>) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">{error.statusCode}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{error.errorTitle}</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, something went wrong. Please try reloading this page or go back to homepage.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a href="/" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Go back to homepage
          </a>
          <a href="/logout/" className="text-sm font-semibold text-gray-900">
            Logout
          </a>
          <a href="https://z3.st/support/" className="text-sm font-semibold text-gray-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
